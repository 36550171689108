import axios from "axios";
const API_URL = "https://tracking-api.otbdevstack.com/v1/";
const getOrder = (values) => {
  return axios.post(
    `${API_URL}voucher-info`,
    {
      certificate: values.field2,
      order_number: values.field1,
    },
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
};

const getOrderByInfo = (values) => {
  return axios.post(
    `${API_URL}basic-info`,
    {
      fname: values.fname,
      lname: values.lname,
      email: values.email,
    },
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
};

const adminLogin = (values) => {
  return axios.post(
    `${API_URL}login`,
    {
      username: values.username,
      password: values.password,
    },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};
const adminForgotPass = (values) => {
  return axios.post(
    `${API_URL}forgot-password`,
    {
      email: values.username,
    },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};

const resetAdminPassword = (pass, token) => {
  return axios.put(
    API_URL + "update-password/" + token,
    {
      password: pass,
    },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};

const resetToekenVerify = (token) => {
  return axios.get(
    API_URL + "check-forgot-token/" + token,

    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};

const AdminListService = {
  getOrder,
  getOrderByInfo,
  adminLogin,
  adminForgotPass,
  resetAdminPassword,
  resetToekenVerify,
};

export default AdminListService;
