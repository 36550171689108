import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function Error404() {
  const location = useLocation();
  useEffect(() => {
    if (location.pathname.includes("/admin")) {
      window.location.href = "/admin";
    } else {
      window.location.href = "/";
    }
  }, [location.pathname]);
  return <></>;
}
