import React, { lazy, Suspense, useEffect } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import Loader from "./Component/Loader";
import Error404 from "./Error404";
import { toast, ToastContainer } from "react-toastify";
import ForgotPass from "./Component/Admin/ForgotPass";
import { useDispatch, useSelector } from "react-redux";
import { jwtDecode } from "jwt-decode";
import { bindActionCreators } from "@reduxjs/toolkit";
import { actionCreaters } from "./Redux";

const Tracker = lazy(() => import("./Component/Tracker/Tracker"));
const Login = lazy(() => import("./Component/Admin/Login"));
const ResetPass = lazy(() => import("./Component/Admin/ResetPass"));

export default function Root() {
  const { accessToken } = useSelector((state) => state.stateVals);
  const location = useLocation();

  const dispatch = useDispatch();
  const userActions = bindActionCreators(actionCreaters, dispatch);

  useEffect(() => {
    if (location.pathname.includes("/admin") && accessToken) {
      const decoded = jwtDecode(accessToken);
      const currentTime = Math.floor(new Date().getTime() / 1000);
      if (currentTime > decoded.exp) {
        toast.error("Request time out!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });

        userActions.logOut("adminLogOut");

        <Navigate to="/admin" replace={true} />;
      }
    }
  }, [location.pathname]);
  return (
    <Suspense fallback={<Loader />}>
      <ToastContainer />
      <Routes>
        <Route path="/" element={<Tracker />} />
        <Route path="/admin" element={<Login />} />
        <Route path="/admin-forgotpassword" element={<ForgotPass />} />
        <Route
          path="/admin/reset-password/:token"
          element={<ResetPass />}
          caseSensitive={true}
        />
        <Route path="*" element={<Error404 />} />
      </Routes>
    </Suspense>
  );
}
